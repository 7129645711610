<template>
  <div class="FSDRights">
    <div class="header">
      <div class="back-btn" @click="back">
        <img src="@/assets/imgs/back-icon.png" alt="" />
      </div>
      <div class="selector-trigger" @click="gotoParkSearch">
        <span v-if="!parkInfo.parkName">请选择网点</span>
        <span class="selected" v-else>
          {{ parkInfo.parkName }}
        </span>
        <span class="clear-btn" v-if="parkInfo.parkName" @click.stop="clearClick">
          <img src="@/assets/imgs/input-clear.png" alt="" />
        </span>
        <img class="arrow-down" src="@/assets/imgs/arrBottom.png" alt="" />
      </div>
    </div>
    <div class="content">
      <div class="content-inner">
        <div class="list-wrap">
          <van-list
            class="license-van-list"
            v-if="records.length > 0"
            v-model="loading"
            :finished="records.length >= total"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <ul class="license-list list">
              <li v-for="item in records" :key="item.vinId" class="list-item">
                <div class="list-item-row">
                  <div class="row-key vinId">{{ item.vinId }}</div>
                  <div class="row-value status" :style="{ color: typeEnums[item.status].color }">
                    {{ typeEnums[item.status].text }}
                  </div>
                </div>
                <div class="list-item-row">
                  <div class="row-key">网点</div>
                  <div class="row-value">{{ item.parkName }}</div>
                </div>
                <div v-if="item.endTime" class="list-item-row">
                  <div class="row-key">失效时间</div>
                  <div class="row-value">{{ item.endTime }}</div>
                </div>
                <div class="list-item-row" v-if="item.status !== 3">
                  <div v-if="item.remainingDay <= 0" class="row-key">过期天数</div>
                  <div v-else class="row-key">剩余天数</div>
                  <div v-if="item.remainingDay === 999999" class="row-value">
                    <span>永久有效</span>
                  </div>
                  <div v-else-if="item.remainingDay > 0" class="row-value">
                    <span v-if="item.remainingDay <= 30" class="warning"
                      >即将到期 {{ item.remainingDay }}天</span
                    >
                    <span v-else>{{ item.remainingDay }}天</span>
                  </div>
                  <div v-else class="row-value">
                    <span>{{ Math.abs(item.remainingDay) }}天</span>
                  </div>
                </div>
              </li>
            </ul>
          </van-list>
          <div v-else-if="firstCompeted" class="none-data">
            <img src="@/assets/imgs/no_data.png" alt="" />
            <span>暂无数据</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus';
import { fsdList } from '@/api/apiv2';
import moment from 'moment';

const typeEnums = {
  0: {
    text: '待生效',
    color: 'rgba(151, 152, 171, 1)',
  },
  1: {
    text: '生效中',
    color: 'rgba(38, 197, 117, 1)',
  },
  2: {
    text: '已过期',
    color: 'rgba(255, 45, 85, 1)',
  },
  3: {
    text: '已暂停',
    color: 'rgba(244, 177, 0, 1)',
  },
};

export default {
  components: {},
  data() {
    return {
      parkInfo: {},
      records: [],
      loading: false,
      pageSize: 15,
      total: 0,
      current: 0,
      currentParkCode: undefined,
      typeEnums,
      firstCompeted: false,
    };
  },
  filters: {
    formatTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  methods: {
    clearClick() {
      this.parkInfo = {};
      this.getList();
    },
    gotoParkSearch() {
      this.$router.push({ path: '/searchPark' });
    },
    onLoad() {
      this.getList(false);
    },
    getList(globalLoading = true) {
      // 切换网格用外部loading其他用list内部
      if (globalLoading) {
        this.$loadingCircle.start();
      } else {
        this.current += 1;
      }
      this.loading = true;

      fsdList({
        parkCode: this.parkInfo.parkCode,
        pageSize: this.pageSize,
        pageNo: this.current,
        timestamp: Date.now(),
      })
        .then((res) => {
          if (this.parkInfo.parkCode !== this.currentParkCode) {
            this.records = res.records;
          } else {
            this.records = [...this.records, ...res.records];
          }
          this.currentParkCode = this.parkInfo.parkCode;
          this.total = res.total;
        })
        .finally(() => {
          this.firstCompeted = true;
          this.loading = false;

          if (globalLoading) this.$loadingCircle.end();
        });
    },
    back() {
      window.location.href = 'neolix://more';
    },
    selectPark(parkInfo) {
      this.parkInfo = parkInfo;
      this.current = 0;
      this.getList();
    },
  },
  created() {
    bus.on('onSelectPark', this.selectPark);
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.FSDRights {
  font-size: initial;
  background: #f6f7f8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    height: 40px;
    background: white;
    padding: 0 8px 0 10px;
    box-sizing: border-box;
    .back-btn {
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }

    .selector-trigger {
      margin-left: 7px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      background: #f6f7f8;
      border-radius: 6px;

      span {
        margin-left: 9px;
        color: rgba(151, 152, 171, 1);
        font-size: 14px;
        &.selected {
          flex: 1;
          color: rgba(0, 0, 0, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .clear-btn {
        width: 20px;
        height: 20px;
        text-align: center;
        margin-right: 8px;
        img {
          margin-top: 2px;
          width: 16px;
          height: 16px;
        }
      }

      .arrow-down {
        width: 14px;
        height: 14px;
        margin-right: 9px;
      }
    }
  }

  .content {
    flex: 1;
    overflow: hidden;
    max-height: calc(100vh - 40px);
    // margin-top: 15px;

    .content-inner {
      overflow: auto;
      padding-top: 15px;
      .license-van-list {
        max-height: calc(100vh - 40px);
        ::v-deep .van-list__finished-text {
          padding-bottom: 20px;
        }
      }
      .none-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        img {
          width: 130px;
          height: 130px;
          margin-top: 30%;
          margin-bottom: 15px;
        }
      }
      .list {
        margin: 0 14px;
        box-sizing: border-box;
        padding: 0;
        .list-item {
          padding: 14px;
          box-sizing: border-box;
          background: white;
          border-radius: 12px;
          margin-bottom: 14px;
          &-row {
            &:not(:last-child) {
              margin-bottom: 8px;
            }
            display: flex;
            justify-content: space-between;
            .row-key {
              flex: 1 0 auto;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 20px;
              color: rgba(151, 152, 171, 1);
            }

            .row-value {
              text-align: right;
              flex: 1 0 auto;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 20px;
              color: rgba(0, 0, 0, 1);
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }

        &.license-list {
          .row-key {
            &.vinId {
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0px;
              line-height: 22px;
              color: rgba(0, 0, 0, 1);
            }
          }
          .row-value {
            &.status {
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0px;
              line-height: 20px;
              color: rgba(0, 0, 0, 1);
            }
            .warning {
              color: rgba(254, 128, 0, 1);
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
</style>
